.item-wrapper {
  min-height: 100%;
  padding-bottom: 15px;
  margin: 0 -15px;
  background-color: #fff;
}
.item-wrapper .image {
  height: auto;
  -webkit-box-shadow: 0 0 12px rgba(51,51,51,0.13);
  box-shadow: 0 0 12px rgba(51,51,51,0.13);
  border-radius: 0 0 10px 10px;
  width: 100%;
}
.item-wrapper .cont {
  margin: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.item-wrapper .product-info-list-title {
  font-family: PFDinTextCompPro;
  margin: 0;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.198px;
}
.item-wrapper .product-info-list-wrapper .product-info-list-title {
  font-family: PFDinTextCompPro;
  margin: 0;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.198px;
  text-transform: none;
}
.item-wrapper .product-info-list-wrapper .product-info-list {
  margin: 0 20px 15px 0;
  font-size: 14px;
  line-height: 16px;
}
.item-wrapper .product-info-list-wrapper .product-info-list.circle>li {
  margin: 0 5px 0 10px;
  position: relative;
  display: inline-block;
}
.item-wrapper .product-info-list-wrapper .product-info-list.circle>li:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #333;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -10px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.item-wrapper .info {
  margin-bottom: 16px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.item-wrapper .info .detail-basket-button {
  width: 100%;
  max-width: 182px;
  min-width: fit-content;
  padding: 10px;
  margin-right: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all 3s ease-out;
  -moz-transition: all 3s ease-out;
  -o-transition: all 3s ease-out;
  -ms-transition: all 3s ease-out;
  transition: all 3s ease-out;
}
.item-wrapper .info .detail-basket-button:active {
  background-color: #a8a7a7;
  max-width: 100px;
}
.item-wrapper .portion-title {
  margin-bottom: 16px;
}
.item-wrapper .portion-title .title-text {
  font-family: PFDinTextCompPro;
  font-size: 28px;
  margin-bottom: 5px;
}
.item-wrapper .portion-title .weight {
  font-size: 16px;
  display: inline-block;
  border-bottom: 1px solid #ff8522;
}
.item-wrapper .remind-time {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
  font-size: 14px;
  color: #999;
}
.item-wrapper .remind-time .remind-time-text {
  font-size: 1.2em;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.item-wrapper .remind-time .icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.portion-price {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  font-family: PFDinTextCompPro;
  width: fit-content;
  color: #ff8522;
  margin-right: 25px;
  height: 40px;
}
.portion-price .portion-price-details {
  position: relative;
}
.portion-price .portion-price-currency {
  font-size: 16px;
  position: absolute;
  bottom: 2px;
  color: #a8a7a7;
}
.portion-price .portion-price-integer {
  line-height: 1;
  display: inline-block;
  margin-right: 5px;
  vertical-align: top;
  font-size: 40px;
}
.portion-price .portion-price-fraction {
  font-size: 22px;
  line-height: 1;
}
.product-info-list-button {
  margin: 0 5px 5px 0;
  padding: 7px 14px;
}
.product-info-list-button,
.detail-basket-button {
  font-size: 13px;
  line-height: 13px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 30px;
  width: fit-content;
  display: inline-block;
  border: 1px solid #a8a7a7;
  background-color: #fff;
  color: #a8a7a7;
}
.product-info-list-button.product-info-list-button__active,
.detail-basket-button {
  background-color: #ff8522;
  border: 1px solid #ff8522;
  color: #fff;
}
/*# sourceMappingURL=src/js/components/DetailItem/DetailItem.css.map */