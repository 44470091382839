@import "~vars"

.cart-total
	position fixed
	left 0
	bottom 0
	width 100%
	background white
	display flex
	height 65px
	padding 17px 29px
	box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25)
	justify-content space-between
	align-items center
	font-family $proxima
	z-index 101
	.total-price
		.total-price-title, .total-price-value
			color $color
			font-size 14px
			line-height 20px
			margin 0
		.total-price-value
			font-weight 500
			font-size 16px
			line-height 20px
		.total-price-title
			font-size 14px
			line-height 20px
	.create-order-button .btn
		width 140px
		height 35px
		text-transform capitalize
		font-weight normal
		font-size 14px
		line-height 17px
		&.create-order-button_disabled
			background-color #999999
			color white
			cursor not-allowed

