.layout {
  width: 100%;
  min-height: 100%;
  background: #f2f2f2;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
}
.container {
  width: auto;
  min-height: 100%;
  background: $container;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}
.content {
  width: 100%;
  height: 100%;
  padding: 60px 15px 60px;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  position: relative;
}
@media only screen and (min-width: 500px) {
  .content .categories {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -o-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .content .categories .list-item-wrapper img {
    padding: 0 15px;
    height: 400px;
    object-fit: contain;
  }
  .content .categories .list-item-wrapper .title-text {
    height: 46px;
    overflow: hidden;
  }
  .content .categories>li {
    width: 50%;
  }
  .content .categories>li:nth-child(2n) {
    width: calc(50% - 15px);
    margin-left: 15px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
.back-to-top {
  z-index: 90;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  background: #ff8522;
  position: fixed;
  bottom: 60px;
  right: 60px;
  border-radius: 50%;
  -webkit-transform: translate(110px, 0);
  -moz-transform: translate(110px, 0);
  -o-transform: translate(110px, 0);
  -ms-transform: translate(110px, 0);
  transform: translate(110px, 0);
  -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72);
  -moz-transition: -moz-transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72);
  -o-transition: -o-transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72);
  -ms-transition: -ms-transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72);
  transition: transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72);
}
@media (max-width: 768px) {
  .back-to-top {
    bottom: 70px;
    width: 42px;
    height: 42px;
  }
}
.back-to-top.show {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
@media (max-width: 768px) {
  .back-to-top.show {
    right: 20px;
  }
}
.back-to-top i.icon {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  color: #fff;
  font-size: 28px;
  width: 40px;
  height: 24px;
}
@media (max-width: 768px) {
  .back-to-top i.icon {
    width: 22px;
    height: 16px;
  }
}
.-mobile {
  display: none;
}
@media (max-width: 768px) {
  .-mobile {
    display: block;
  }
}
@media (max-width: 768px) {
  .-desktop {
    display: none;
  }
}
/*# sourceMappingURL=src/js/components/Layout/Layout.css.map */