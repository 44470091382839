.recommended-wrapper
	background-color white
	margin -15px -15px 0
	h3
		font-family PFDinTextCompPro
		margin 0 15px
		font-size 30px
		line-height 30px
		letter-spacing -0.198px
		font-weight normal
.recommended-list-holder
	overflow-x auto
	overflow-y hidden
	padding-left 15px
	max-height 600px

	.recommended-list
		display flex
	.list-item-wrapper
		flex 0 0 290px
		margin-right 10px