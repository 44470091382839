@import "~vars"


.menu-catalog
	width 100%
	height 100%
	max-height 100vh
	padding-bottom 60px
	background-color white
	position fixed
	top 0
	left 0
	overflow hidden
	opacity 0
	transform translateY(100%)
	transition all $tdur ease-in-out
	&.open
		transform translateY(0%)
		opacity 1


	.catalog
		width 100%
		min-width 280px
		max-width 500px
		max-height 100%
		padding 20px 50px
		margin 0 auto
		overflow auto
	.sub-catalog
		display none
		padding-left 15px

	.category-item
	.sub-category-item
		height auto
		min-height 30px
		display flex
		flex-direction column
		justify-content center
	.category-item
		font-size 16px
		&.active
			color $primary
			.sub-catalog
				display block
			.category-item-title
				.icon-chevron-down
					transform rotateX(180deg)
	.sub-category-item
		font-size 14px
		color black
		&.active
			color $primary

	.category-item-title
		height 30px
		display flex
		justify-content space-between
		align-items center
		.icon-chevron-down
			margin-left 15px
			svg
				size(11px)