@import "~vars"

.cart-container
	background white
	height 100%
	margin-bottom 15px
	.cart-title
		font-family $pfdintextcomp
		font-size 24px
		line-height 29px
		color $color
		text-transform uppercase
		padding $paddingXS $gutter
		font-weight normal
		margin-bottom 0

.empty-cart-modal
	text-align center
	.description
		padding 0 10px 10px