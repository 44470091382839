@import "~vars"

.menu
	width 100%
	height 60px
	background-color white
	flexCenter()
	box-shadow 0 -5px 20px rgba(0,0,0,.2)
	z-index 100
	position fixed
	bottom 0

	.menu-btn
		width 182px
		height 40px
		padding-left 45px
		font normal 14px $font
		z-index 1
		&.active
			.icon-chevron-down
				transform rotateX(0deg)
		.icon-chevron-down
			margin-left 15px
			transform rotateX(180deg)
			svg
				size(11px)