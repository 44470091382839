@import "nib"


/***FONTS****/
$proxima = "ProximaNova", Helvetica, Arial, sans-serif
$novito = "NovitoNova", Helvetica, Arial, sans-serif
novito()
	font-family $novito
	text-transform uppercase

$pfdintextcomp = "PFDinTextCompPro", Helvetica, Arial, sans-serif
$pfdintextcond = "PFDinTextCondPro", Helvetica, Arial, sans-serif

$font = $proxima

$thin = 100
$light = 300
$normal = unquote("normal")
$regular = unquote("normal")
$medium = 500
$semibold = 600
$bold = unquote("bold")
$extrabold = 800
$black = 900
$ultrabold = 900

$nav-bg = white
$nav-color = #6e6e6e

/* VARIABLES */
$font = $proxima

$fontBase = 18px
$fontSize1 = 12px
$fontSize2 = 14px
$fontSize3 = 16px
$fontSize4 = 24px
$fontSize5 = 36px
$fontSize6 = 72px

$tdur = .35s
$transition = unquote(join(" ", "all", $tdur))
$cubic = cubic-bezier(0.23, 1, 0.32, 1)
$r = 3px
$boxShadow = 0 15px 20px rgba(black, .13)

$retina = "only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)"

/* BUILD TYPE VARS*/
$isSilpo = true

/* COLORS */
$color = #333333
$gray = #a8a7a7
$gray-l = #e5e5e5
$gray-2 = #cccccc
$borderColor = #e8e8e8

$primary = #ff8522
$secondary = #6f509c
$danger = #f71f2f
$footer-bg-color	=	#221f1f
$hr-bg = #94949445

$white = #fff
$orange = #faa30c
$violet = #573F7B
$cyan = #218FA3
$green = #4BB7A1
$ltgreen = #97BE00
$olive = #B7BE00
$violetLight = #6F50C4
$blue = #00a0e6
$ltBlue = #218FA3
$darkBlue = #0a5485
$yellow = #ffd318

// HOVERS
$colorHover = #4c4c4c
$primaryHover = #ff943d
$fb-hover = #4867aa;
$orangeHover = #fbae2a
$violetHover = #6f5a8e
$cyanHover = #3c9dae
$greenHover = lighten(#4BB7A1, 10)
$ltgreenHover = lighten(#97BE00, 10)
$ltblueHover = lighten(#218FA3, 10)
$blueHover = lighten(#00a0e6, 10)
$darkblueHover = lighten(#0a5485, 10)
$oliveHover = #c1c724
$basketCounter = #ff9933
$yellowHover = #ffd318db
$disabledColor = $gray
$disabledBg = #f2f2f2


/* FORM */
$errorHeight = 28px
$formBorColor = $gray
$formActiveBorColor = $violet
$formFontSize = 14px
$formLineHeight = 17px
$formArrowWidth = 20px


/* SOCIAL */
$fbColor = #3b5997
$fbColorHover = #536da4
$twColor = #55acee
$twColorHover = lighten($twColor, 10)


/* SIZES */
$minWidth = 320px
$maxWidth = 1920px
$gutter = 15px
$padding = 50px
$paddingXS = 20px
$paddingXXS = 10px
$paddingTablet = 15px
$paddingPhone = 10px
$menuWidth = 280px
$menuWidthXL = 327px


/* MEDIA QUERIES */
$menuToggle = 1100px

/* SCREENS */
$tablet = 500px


/* FUNCTIONS */
clearfix()
	zoom 1
	&:before,
	&:after
		content ""
		display table
	&:after
		clear both

size()
	if length(arguments) == 1
		width: arguments[0]
		height: arguments[0]
	else
		width: arguments[0]
		height: arguments[1]

absolute-center()
	position absolute
	top 50%
	left 50%
	transform translate(-50%, -50%)

br-lefttop(args...)
	-webkit-border-top-left-radius args
	-moz-border-radius-topleft args
	border-top-left-radius args

br-leftbottom(args...)
	-webkit-border-bottom-left-radius args
	-moz-border-radius-bottomleft args
	border-bottom-left-radius args

br-righttop(args...)
	-webkit-border-top-right-radius args
	-moz-border-radius-topright args
	border-top-right-radius args

br-rightbottom(args...)
	-webkit-border-bottom-right-radius args
	-moz-border-radius-bottomright args
	border-bottom-right-radius args

pre()
	white-space pre-wrap
	word-wrap break-word

center()
	transform translate(-50%, -50%)

multiLinesOverflow(lines-to-show)
	display block
	display -webkit-box
	max-height @line-height* lines-to-show
	-webkit-line-clamp lines-to-show
	-webkit-box-orient vertical
	overflow hidden
	text-overflow ellipsis


bgPosition($pos, $size = 0)
	return "calc(%s% - %s * %s)" % ($pos (50 - $pos) / 100 $size)

lineHeight($lineHeight)
	$fontSize = @font-size || $lineHeight
	line-height unit($lineHeight/$fontSize, "")

lh($lineHeight)
	lineHeight($lineHeight)

novito()
	text-transform uppercase
	font-family $novito

flexCenter()
	display flex
	justify-content center
	align-items center

/* Media queries mixins */
super-wide()
	@media (min-width: 2560px)
		{block}

wide()
	@media (max-width: 1919px)
		{block}

small-wide()
	@media (max-width: 1600px)
		{block}

desktop()
	@media (max-width: 1300px)
		{block}

laptop()
	@media (max-width: 1023px)
		{block}

tablet()
	@media (max-width: 768px)
		{block}

phone()
	@media (max-width: 500px)
		{block}

isMobileMenu()
	@media (max-width: $menuToggle)
		{block}

media(resolution)
	@media only screen and (max-width resolution)
		{block}

hover()
	@media (hover: hover)
		{block}
