/*
 * Size utility.
 *
 * Synopsis:
 *
 *   size: <width> <height> | <width & height>
 *
 * Examples:
 *
 *     size: 100% 30px
 *       yields:
 *         width: 100%
 *         height: 30px
 *
 *     size: 5px
 *       yields:
 *         width: 5px
 *         height: 5px
 *
 */

size()
  if length(arguments) == 1
    width: arguments[0]
    height: arguments[0]
  else
    width: arguments[0]
    height: arguments[1]
