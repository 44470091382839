.no-data {
  background: #fff;
  text-align: center;
  font-size: 22px;
  line-height: 1.181818181818182;
  position: relative;
  padding: 50px;
}
@media (max-width: 768px) {
  .no-data {
    padding: 30px;
    font-size: 19px;
  }
}
@media (max-width: 500px) {
  .no-data {
    font-size: 17px;
  }
}
.no-data:before {
  content: '';
  background: #e5e5e5;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  margin: 0 50px;
}
.no-data__content {
  margin: auto;
}
.no-data--apart {
  margin-top: 15px;
}
.no-data--apart:before {
  display: none;
}
/*# sourceMappingURL=src/js/components/UI/NoData/NoData.css.map */