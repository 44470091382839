@import "~vars"

.item-wrapper
	min-height 100%
	padding-bottom 15px
	margin  0 -15px
	background-color white
	.image
		height auto
		box-shadow: 0 0 12px rgba(51, 51, 51, 0.13);
		border-radius: 0 0 10px 10px;
		width 100%
	.cont
		margin 15px
		display flex
		flex-direction column
		justify-content center
	.product-info-list-title
			font-family PFDinTextCompPro
			margin 0
			font-size: 22px;
			line-height: 30px;
			letter-spacing: -0.198px;
		.product-info-list-wrapper
			.product-info-list-title
				font-family PFDinTextCompPro
				margin 0
				font-size 22px
				line-height 30px
				letter-spacing -0.198px
				text-transform none
			.product-info-list
				margin 0 20px 15px 0
				font-size: 14px;
				line-height: 16px;
				&.circle>li
					margin 0 5px 0 10px
					position relative
					display inline-block
					&:before
						content ""
						width 4px
						height 4px
						background-color #333
						border-radius 50%
						position absolute
						top 50%
						left -10px
						transform translate(0, -50%)

		.info
			margin-bottom 16px
			display flex
			justify-content space-between
			.detail-basket-button
				width 100%
				max-width 182px
				min-width fit-content
				padding 10px
				margin-right 10px
				flexCenter()
				cursor pointer
				transition all 3s ease-out
				&:active
					background-color $gray
					max-width 100px


	.portion-title
		margin-bottom 16px
		.title-text
			font-family PFDinTextCompPro
			font-size 28px
			margin-bottom 5px
		.weight
			font-size 16px
			display inline-block
			border-bottom 1px solid $primary
	.remind-time
		margin-bottom 20px
		display flex
		align-self center
		font-size 14px
		color #999999
		.remind-time-text
			font-size 1.2em
			justify-content center
		.icon
			width 20px
			height 20px
			margin-right 5px
.portion-price
	display flex
	justify-content center
	font-family PFDinTextCompPro
	width fit-content
	color $primary
	margin-right 25px
	height 40px
	.portion-price-details
		position relative
	.portion-price-currency
		font-size 16px
		position absolute
		bottom 2px
		color #A8A7A7
	.portion-price-integer
		line-height 1
		display inline-block
		margin-right 5px
		vertical-align top
		font-size 40px
	.portion-price-fraction
		font-size 22px
		line-height 1
.product-info-list-button
	margin 0 5px 5px 0
	padding  7px 14px
.product-info-list-button
.detail-basket-button
	font-size 13px
	line-height 13px
	box-sizing: border-box;
	border-radius: 30px;
	width fit-content
	display inline-block
	border 1px solid $gray
	background-color white
	color $gray
.product-info-list-button.product-info-list-button__active
.detail-basket-button
	background-color $primary
	border 1px solid $primary
	color white