.error-page-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-height: 100%;
  height: 100%;
  background: url("../../../img/default-background.jpg") #463265;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center;
}
.error-page-wrapper .error-page-holder {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: distribute;
  -moz-box-pack: distribute;
  -o-box-pack: distribute;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  height: auto;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 15px;
  padding: 10px;
}
.error-page-wrapper .error-page-holder .error-code {
  font-family: NovitoNova;
  font-size: 120px;
  color: #fff;
}
.error-page-wrapper .error-page-holder .error-text {
  text-align: center;
  font-family: NovitoNova;
  font-size: 32px;
  color: #fff;
  text-transform: uppercase;
}
.error-page-wrapper .error-page-holder .home-button {
  font-family: ProximaNova;
  font-size: 13px;
  line-height: 13px;
  color: #fff;
  padding: 14px 53px;
  border: 1px solid #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 30px;
  width: fit-content;
  margin: 20px auto;
  margin-top: 30px;
  text-transform: uppercase;
}
/*# sourceMappingURL=src/js/pages/NotFound/NotFound.css.map */