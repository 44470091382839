@import "~vars"

#header
	width 100%
	min-width 280px
	height auto
	position fixed
	left 0
	top 0
	box-shadow 0 0 12px rgba(0, 0, 0, 0.2)
	pointer-events none
	z-index 10

	.header-wrapper
		border-bottom 1px solid $borderColor
		height 60px
		width 100%
		background white
		pointer-events auto
		display flex
		align-items center
		justify-content space-between
		.header-left
			display flex
			&.cart-menu
				width 100%
				.cart-menu-button
					position absolute
					color #999
					font-family $proxima
					font-size 12px
					line-height 15px
					text-transform capitalize
					-webkit-tap-highlight-color transparent
					.icon-left-arrow
						width 13px
					span
						margin 2px 0 0 10px
				.cart-menu-title
					margin auto
					font-family $pfdintextcomp
					font-size 20px
					line-height 24px
					text-transform capitalize
		.header-right
			display flex
			align-items center
			.search-btn
				size(24px)
				margin-right 16px
				color #a8a7a7
				cursor pointer
				&.active
					color $primary
				svg
					size(24px)
			.tooltip
				position relative
				margin-right 16px
				z-index 20
				&:hover:after
					border: solid;
					border-color: white transparent;
					border-width: 0 12px 12px 12px;
					content: "";
					left: 50%
					transform translate(-50%, 18px)
					bottom: 0;
					position: absolute;

				&:hover .tooltip-data
					display block
				.tooltip-data
					display none
					position absolute
					background: white
					color: #333
					margin: 0
					font-size: 16px
					padding: 15px
					max-width 200px
					width: 70vw
					height auto
					box-shadow: 0 0 12px rgba(51, 51, 51, 0.3);
					left 40px
					bottom -12px
					transform translate(-100%, 100%)

					&>p
						margin 0
					.store
						font-size 16px
						line-height 18px
						letter-spacing -0.05em
						margin-bottom 18px

					.table
						font-size 20px
						line-height 20px
						text-align center
						text-transform uppercase

				.icon-marker-outline
					margin 0 auto
					color #a8a7a7
					width 19px
					height 24px
					&:hover
						color #FF8522

			a
				padding-left 0
				padding-right 16px
				.menu-header_button-number
					letter-spacing: 0.02em;
					pointer-events: auto;
					cursor: pointer;
					line-height: 1;
					box-sizing: border-box;
					background-color: #f93;
					color: #fff;
					font-weight: 700;
					display: inline-block;
					width: 20px;
					height: 20px;
					font-size: 12px;
					text-align: center;
					position: absolute;
					border-radius: 10px;
					right: 8px;
					top: 2px;
					.menu-header_button-value
						letter-spacing: 0.02em;
						pointer-events: auto;
						cursor: pointer;
						line-height: 1;
						color: #fff;
						font-weight: 700;
						font-size: 12px;
						text-align: center;
						box-sizing: border-box;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);

		.logo
			margin 10px 15px 5px
			&.positano
				margin-left 3px

		.mobile-menu-link
			-webkit-tap-highlight-color transparent