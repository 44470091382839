@import "~vars"

.error-page-wrapper
	display flex
	align-items center
	justify-content center
	min-height 100%
	height 100%
	background url("../../../img/default-background.jpg") #463265
	background-size cover
	background-position center
	.error-page-holder
		display flex
		align-items center
		justify-content space-around
		height auto
		flex-direction column
		margin 15px
		padding 10px

		.error-code
			font-family NovitoNova
			font-size 120px
			color: white
		.error-text
			text-align center
			font-family NovitoNova
			font-size 32px
			color: white
			text-transform uppercase
		.home-button
			font-family: ProximaNova;
			font-size: 13px;
			line-height: 13px;
			color: white;
			padding: 14px 53px;
			border: 1px solid white;
			box-sizing: border-box;
			border-radius: 30px;
			width: fit-content;
			margin: 20px auto;
			margin-top 30px
			text-transform uppercase