//@import "nib"
@import "~vars"

.no-data
	background white
	text-align center
	font-size 22px
	lh(26)
	position relative
	padding 50px
	+tablet()
		padding 30px
		font-size 19px
	+phone()
		font-size 17px

	&:before
		content ''
		background $gray-l
		height 1px
		absolute left top
		right 0
		margin 0 @padding

.no-data__content
	margin auto

.no-data--apart
	margin-top $gutter

	&:before
		display none