.cart-total {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  height: 65px;
  padding: 17px 29px;
  -webkit-box-shadow: 0 4px 14px rgba(0,0,0,0.25);
  box-shadow: 0 4px 14px rgba(0,0,0,0.25);
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  z-index: 101;
}
.cart-total .total-price .total-price-title,
.cart-total .total-price .total-price-value {
  color: #333;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}
.cart-total .total-price .total-price-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.cart-total .total-price .total-price-title {
  font-size: 14px;
  line-height: 20px;
}
.cart-total .create-order-button .btn {
  width: 140px;
  height: 35px;
  text-transform: capitalize;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}
.cart-total .create-order-button .btn.create-order-button_disabled {
  background-color: #999;
  color: #fff;
  cursor: not-allowed;
}
/*# sourceMappingURL=src/js/components/Cart/CartTotal/CartTotal.css.map */