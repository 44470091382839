@import "~vars"

.layout
	width 100%
	min-height 100%
	background #f2f2f2
	font-family $font

.container
	width auto
	min-height 100%
	background $container
	display flex
	flex-direction column
	position relative

.content
	width 100%
	height 100%
	padding 60px 15px 60px
	font-family $font
	position relative

	@media only screen and (min-width $tablet)
		.categories
			display flex
			flex-wrap wrap
			.list-item-wrapper
				img
					padding 0 15px
					height 400px
					object-fit contain
				.title-text
					height: 46px;
					overflow: hidden;
			&>li
				&:nth-child( 2n)
					width calc(50% - 15px)
					margin-left 15px
					box-sizing border-box
				width 50%


.back-to-top
	z-index 90
	display flex
	justify-content center
	align-items center
	size(50px, 50px)
	cursor pointer
	background $primary
	position fixed
	bottom 60px
	right 60px
	border-radius 50%
	transform translate(110px, 0);
	transition transform 0.8s cubic-bezier(0.88, -0.36, 0.16, 1.72)
	+tablet()
		bottom 70px
		width 42px
		height 42px

	&.show
		transform translate(0, 0);
		+tablet()
			right 20px

	i.icon
		transform rotate(-90deg)
		color white
		font-size 28px
		width 40px
		height 24px
		+tablet()
			width 22px
			height 16px

.-mobile
	display none
	+tablet()
		display block

.-desktop
	+tablet()
		display none