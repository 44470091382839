.recommended-wrapper {
  background-color: #fff;
  margin: -15px -15px 0;
}
.recommended-wrapper h3 {
  font-family: PFDinTextCompPro;
  margin: 0 15px;
  font-size: 30px;
  line-height: 30px;
  letter-spacing: -0.198px;
  font-weight: normal;
}
.recommended-list-holder {
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 15px;
  max-height: 600px;
}
.recommended-list-holder .recommended-list {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
.recommended-list-holder .list-item-wrapper {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 0 0 290px;
  -ms-flex: 0 0 290px;
  flex: 0 0 290px;
  margin-right: 10px;
}
/*# sourceMappingURL=src/js/components/RecommendedList/RecommendedList.css.map */