.modal.hide {
  display: none;
}
.modal.show {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  background: rgba(0,0,0,0.65);
  width: 100%;
  pointer-events: auto;
  -webkit-box-shadow: 1px 0 20px -10px #000;
  box-shadow: 1px 0 20px -10px #000;
  -webkit-transition: left 0.35s;
  -moz-transition: left 0.35s;
  -o-transition: left 0.35s;
  -ms-transition: left 0.35s;
  transition: left 0.35s;
  z-index: 101;
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.modal.show .modal-main {
  position: absolute;
  background: #fff;
  border-radius: 10px;
  min-width: 250px;
  padding: 46px 14px 20px;
  max-width: 90%;
}
.modal.show .modal-main .close-button {
  width: 12px;
  height: 12px;
  color: #a8a7a7;
  position: absolute;
  top: 15px;
  right: 15px;
}
.modal.show .modal-main .title {
  font-family: ProximaNova;
  font-size: 21px;
  line-height: 18px;
  text-align: center;
  color: #333;
  margin: 0 0 5px 0;
}
.modal.show .modal-main .description {
  font-family: ProximaNova;
  font-size: 18px;
  line-height: 18px;
  color: #333;
  margin: 0;
}
/*# sourceMappingURL=src/js/components/Modal/Modal.css.map */