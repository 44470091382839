.list-item-wrapper {
  margin: 15px 0;
  background-color: #fff;
  min-height: 100%;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 12px rgba(51,51,51,0.13);
  box-shadow: 0 0 12px rgba(51,51,51,0.13);
}
.list-item-wrapper .image {
  height: auto;
  border-radius: 10px 10px 0 0;
  width: 100%;
}
.list-item-wrapper .cont {
  padding: 15px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.list-item-wrapper .cont .product-info-list-wrapper .product-info-list-title {
  font-family: PFDinTextCompPro;
  margin: 0;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.198px;
}
.list-item-wrapper .cont .product-info-list-wrapper .product-info-list {
  margin: 0 20px 15px 0;
  font-size: 14px;
  line-height: 16px;
}
.list-item-wrapper .cont .product-info-list-wrapper .product-info-list.circle>li {
  margin: 0 5px 0 10px;
  position: relative;
  display: inline-block;
}
.list-item-wrapper .cont .product-info-list-wrapper .product-info-list.circle>li:before {
  content: "";
  width: 4px;
  height: 4px;
  background-color: #333;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -10px;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.list-item-wrapper .cont .basket-button {
  font-size: 13px;
  color: #fff;
  padding: 12px 62px;
  background-color: #ff8522;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 30px;
  display: inline-block;
  margin: 0 auto;
  cursor: pointer;
  -webkit-transition: all 3s ease-out;
  -moz-transition: all 3s ease-out;
  -o-transition: all 3s ease-out;
  -ms-transition: all 3s ease-out;
  transition: all 3s ease-out;
}
.list-item-wrapper .cont .basket-button:active {
  background-color: #a8a7a7;
  padding: 10px;
}
.list-item-wrapper .cont .weight {
  font-size: 16px;
  border-bottom: 2px solid #ff8522;
  height: 20px;
  -webkit-align-self: center;
  align-self: center;
  -ms-flex-item-align: center;
  display: inline-block;
  margin-bottom: 20px;
}
.list-item-wrapper .cont .info {
  margin-bottom: 20px;
}
.list-item-wrapper .title-text {
  font-family: PFDinTextCompPro;
  font-size: 24px;
  margin-bottom: 16px;
}
/*# sourceMappingURL=src/js/components/ListItem/ListItem.css.map */