.cart-container {
  background: #fff;
  height: 100%;
  margin-bottom: 15px;
}
.cart-container .cart-title {
  font-family: "PFDinTextCompPro", Helvetica, Arial, sans-serif;
  font-size: 24px;
  line-height: 29px;
  color: #333;
  text-transform: uppercase;
  padding: 20px 15px;
  font-weight: normal;
  margin-bottom: 0;
}
.empty-cart-modal {
  text-align: center;
}
.empty-cart-modal .description {
  padding: 0 10px 10px;
}
/*# sourceMappingURL=src/js/pages/Cart/Cart.css.map */