.intro
	margin 0 -15px
	background-color #FF8522
	color white
	padding 15px
	&.positano
		background-color #678ba5
	.title
		font-family NovitoNova
		font-size 46px
		line-height 55px
		margin 0
		margin-bottom 15px
		text-transform uppercase
	.description
		font-size 14px
		line-height 16px

.intro-positano-flag
	height 10px
	margin 0 -15px
	background linear-gradient(to right, #c5c1d1 16.7%, #9f1b20 16.7%, #9f1b20 33.3%, #37353d 33.3%, #37353d 50%,
	#c5c1d1 50%, #c5c1d1 66.7%, #9f1b20 66.7%, #9f1b20 83.3%, #37353d 83.3%)