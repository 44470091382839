@import "~vars"

.list-item-wrapper
	margin  15px 0
	background-color white
	min-height 100%
	border-radius 10px
	box-shadow: 0 0 12px rgba(51, 51, 51, 0.13);

	.image
		height auto
		border-radius 10px 10px 0 0
		width 100%
	.cont
		padding  15px
		display flex
		flex-direction column
		justify-content center
		.product-info-list-wrapper
			.product-info-list-title
				font-family PFDinTextCompPro
				margin 0
				font-size: 22px;
				line-height: 30px;
				letter-spacing: -0.198px;
			.product-info-list
				margin 0 20px 15px 0
				font-size: 14px;
				line-height: 16px;
				&.circle>li
					margin 0 5px 0 10px
					position relative
					display inline-block
					&:before
						content ""
						width 4px
						height 4px
						background-color #333
						border-radius 50%
						position absolute
						top 50%
						left -10px
						transform translate(0, -50%)

		.basket-button
			font-size 13px
			color white
			padding  12px 62px
			background-color $primary;
			box-sizing border-box
			border-radius 30px
			display inline-block
			margin 0 auto
			cursor pointer
			transition all 3s ease-out
			&:active
				background-color $gray
				padding 10px

		.weight
			font-size 16px
			border-bottom 2px solid $primary
			height 20px
			align-self center
			display inline-block
			margin-bottom 20px
		.info
			margin-bottom 20px

	.title-text
		font-family  PFDinTextCompPro
		font-size 24px
		margin-bottom 16px