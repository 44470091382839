.cart-comment {
  padding: 0 15px 15px;
  width: 100%;
  min-height: 40px;
}
.cart-comment .cart-comment-edit .cart-comment-input {
  border-bottom: 1px solid #999;
}
.cart-comment .cart-comment-edit .cart-comment-input.not-empty {
  border-bottom: 2px solid #573f7b;
}
.cart-comment .cart-comment-edit .cart-comment-input .btn {
  color: #999;
  float: right;
  padding: 0;
  padding-left: 10px;
  -webkit-tap-highlight-color: transparent;
}
.cart-comment .cart-comment-edit .cart-comment-input .btn.hidden {
  visibility: hidden;
}
.cart-comment .cart-comment-edit .cart-comment-input input {
  width: 100%;
  outline: none;
  border: 0;
  font-size: 14px;
  line-height: 12px;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  padding-bottom: 5px;
}
.cart-comment .cart-comment-edit .cart-comment-input input::placeholder {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
}
/*# sourceMappingURL=src/js/components/Cart/CartComment/CartComment.css.map */