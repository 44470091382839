@import "~vars"

.cart-item
	border-bottom 1px solid #99999947
	.cart-item-wrapper
		display flex
		justify-content space-between
		padding-top 15px
		.cart-item-image
			max-width 40%
			padding 0 10px 0 15px
		.cart-item-details
			width 60%
			.title-with-icon, .price-with-icon, .counter-with-total
				display flex
				justify-content space-between
				padding-right 10px
			.title-with-icon
				.icon
					color #999
			.price-with-icon
				margin-top 20px
				.icon-comments
					color #999
					&.active
						color $primary
			.cart-item-portion-title
				font-family $proxima
				font-size 14px
				line-height 16px
				color $color
			.icon-trash-bucket svg
				size(15px,18px)
			.cart-item-price
				font-family $proxima
				color #333
				font-size 13px
				line-height 16px
			.icon-comments svg
				size(20px)
	.counter-with-total
		display flex
		justify-content space-between
		align-items center
		padding 15px
		.cart-item-total-price
			font-family $proxima
			font-style normal
			font-weight 500
			font-size 16px
			line-height 19px