.btn {
  outline: none;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 7px 19px 8px;
  border: 1px solid transparent;
  font-size: 12px;
  line-height: 1.666666666666667;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  color: #333;
  background: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  text-transform: uppercase;
  letter-spacing: 0.02px;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  transition: all 0.35s;
  -webkit-transition-property: width, height, opacity, color, background, opacity, letter-spacing;
  -moz-transition-property: width, height, opacity, color, background, opacity, letter-spacing;
  -o-transition-property: width, height, opacity, color, background, opacity, letter-spacing;
  -ms-transition-property: width, height, opacity, color, background, opacity, letter-spacing;
  transition-property: width, height, opacity, color, background, opacity, letter-spacing;
}
.btn:disabled,
input[type=file]:disabled + .btn {
  cursor: not-allowed;
}
.btn svg path,
.btn svg polygon,
.btn svg rect,
.btn svg circle {
  -webkit-transition: fill 0.35s;
  -moz-transition: fill 0.35s;
  -o-transition: fill 0.35s;
  -ms-transition: fill 0.35s;
  transition: fill 0.35s;
}
a.btn {
  text-decoration: none;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-primary,
.btn-default,
.btn-white,
.btn-gray,
.border-radius,
.black {
  border-radius: 40px;
}
.btn-primary {
  color: #fff;
  background: #ff8522;
}
.btn-primary:disabled {
  background: #f2f2f2;
  color: #a8a7a7;
}
.btn-primary:not(:disabled):hover,
.btn-primary:not(:disabled):focus {
  background: #ff943d;
  color: #fff;
}
.btn-primary.reversed {
  background: #fff;
  color: #ff8522;
}
.btn-primary.reversed:not(:disabled):hover,
.btn-primary.reversed:not(:disabled):focus {
  background: #fff;
  color: #ff8522;
}
.btn-primary.outline {
  background: #fff;
  color: #ff8522;
  border-color: currentColor;
}
.btn-primary.outline:not(:disabled):hover,
.btn-primary.outline:not(:disabled):focus {
  background: #ff8522;
  color: #fff;
  border-color: transparent;
}
.btn-primary.btn--loading {
  background: #ff8522;
}
.btn--loading {
  color: transparent !important;
  cursor: progress;
}
.btn--loading .btn__loader {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  visibility: visible;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  -ms-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.btn__loader {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  transition: all 0.35s;
  background: url("rolling.svg") no-repeat center;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -8px;
}
.btn-default {
  color: #fff;
  background: $red;
}
.btn-default:not(:disabled):hover,
.btn-default:not(:disabled):focus {
  background: #4c4c4c;
  color: #fff;
}
.btn-default.reversed {
  background: #fff;
  color: $red;
}
.btn-default.reversed:not(:disabled):hover,
.btn-default.reversed:not(:disabled):focus {
  background: #fff;
  color: $red;
}
.btn-default.outline {
  border-color: rgba(51,51,51,0.37);
  color: #333;
  background: transparent;
}
.btn-default.outline:not(:disabled):hover,
.btn-default.outline:not(:disabled):focus {
  border-color: transparent;
  background: #333;
  color: #fff;
}
.btn-default.btn--loading {
  background: #333;
}
.btn-white {
  color: #333;
  background: #fff;
}
.btn-white:not(:disabled):hover,
.btn-white:not(:disabled):focus {
  background: #4c4c4c;
  color: #333;
}
.btn-white.outline {
  border-color: #efede8;
  color: #fff;
  background: transparent;
}
.btn-white.outline:not(:disabled):hover,
.btn-white.outline:not(:disabled):focus {
  border-color: transparent;
  background: #fff;
  color: #333;
}
.btn-gray {
  color: #333;
  background: #f7f7f7;
}
.btn-gray:disabled {
  color: rgba(51,51,51,0.5);
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
}
.btn-gray:not(:disabled):hover,
.btn-gray:not(:disabled):focus {
  background: #333;
  color: #fff;
}
.btn-gray.btn--loading {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  background: #333;
}
.btn-filter-view-mode {
  padding: 0;
  border: 0;
  border-radius: 0;
  width: 37px;
  height: 37px;
  color: rgba(168,167,167,0.7);
}
.btn-filter-view-mode:not(:disabled):hover {
  color: #464646;
}
.btn-filter-view-mode.active {
  color: #ff8522 !important;
  cursor: default;
}
.btn-filter-view-mode.list-view:not(:disabled) {
  color: #464646;
}
.btn-filter-view-mode.list-view:not(:disabled):hover,
.btn-filter-view-mode.list-view:not(:disabled):focus {
  color: #464646;
}
.btn-large {
  padding: 16px 50px 14px;
  letter-spacing: 0.1em;
  font-size: 13px;
  line-height: 1.538461538461539;
}
@media (max-width: 500px) {
  .btn-large {
    padding: 13px 25px 11px;
    font-size: 12px;
  }
}
/*# sourceMappingURL=src/js/components/UI/Button/Button.css.map */