.menu {
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-shadow: 0 -5px 20px rgba(0,0,0,0.2);
  box-shadow: 0 -5px 20px rgba(0,0,0,0.2);
  z-index: 100;
  position: fixed;
  bottom: 0;
}
.menu .menu-btn {
  width: 182px;
  height: 40px;
  padding-left: 45px;
  font: normal 14px "ProximaNova", Helvetica, Arial, sans-serif;
  z-index: 1;
}
.menu .menu-btn.active .icon-chevron-down {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.menu .menu-btn .icon-chevron-down {
  margin-left: 15px;
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.menu .menu-btn .icon-chevron-down svg {
  width: 11px;
  height: 11px;
}
/*# sourceMappingURL=src/js/components/Layout/Menu/Menu.css.map */