@import "~vars"

.cart-comment
	padding 0 15px 15px
	width 100%
	min-height 40px
	.cart-comment-edit
		.cart-comment-input
			border-bottom 1px solid #999
			&.not-empty
				border-bottom 2px solid #573F7B
			.btn
				color #999
				float right
				padding 0
				padding-left 10px
				-webkit-tap-highlight-color transparent
				&.hidden
					visibility hidden
			input
				width 100%
				outline none
				border 0
				font-size 14px
				line-height 12px
				font-family $proxima
				padding-bottom 5px
				&::placeholder
					font-size 12px
					line-height 18px
					letter-spacing -0.02em