@font-face {
  font-family: NovitoNova;
  src: url("../fonts/NovitoNova-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: PFDinTextCondPro;
  src: url("../fonts/PFDinTextCondPro-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: PFDinTextCondPro;
  src: url("../fonts/PFDinTextCondPro-Medium.woff") format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: PFDinTextCompPro;
  src: url("../fonts/PFDinTextCompPro-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: PFDinTextCompPro;
  src: url("../fonts/PFDinTextCompPro-Thin.woff") format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: ProximaNova;
  src: url("../fonts/ProximaNova-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: ProximaNova;
  src: url("../fonts/ProximaNova-Semibold.woff") format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: ProximaNova;
  src: url("../fonts/ProximaNova-Bold.woff") format('woff');
  font-weight: bold;
  font-style: normal;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body,
#root,
.App {
  height: 100%;
  margin: 0;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
}
@media (max-width: 768px) {
  body {
    font-size: 13px;
  }
}
a,
.a-like {
  color: #333;
  text-decoration: none;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  -ms-transition: all 0.35s;
  transition: all 0.35s;
  cursor: pointer;
}
a:hover,
.a-like:hover {
  text-decoration: none;
  color: #ff8522;
}
a:hover,
.a-like:hover,
a:active,
.a-like:active {
  outline: 0;
}
a {
  text-decoration: none;
  color: inherit;
}
a:focus,
button:focus,
a:hover,
button:hover,
a:active,
button:active {
  outline: none;
}
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
img {
  max-width: 100%;
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}
hr {
  height: 2px;
  border: 0;
  color: #ff8522;
  margin: 15px 0;
}
hr::before {
  content: '';
  height: 100%;
  width: 28px;
  display: inline-block;
  vertical-align: top;
  background: #ff8522;
}
h1 {
  font-size: 48px;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
}
@media (max-width: 768px) {
  h1 {
    font-size: 32px;
  }
}
h2 {
  font-size: 32px;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  line-height: 0.875;
}
@media (max-width: 768px) {
  h2 {
    font-size: 23px;
  }
}
h3,
.heading3 {
  font-size: 23px;
  line-height: 0.875;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  font-weight: bold;
}
@media (max-width: 768px) {
  h3,
  .heading3 {
    font-size: 22px;
  }
}
h4,
.heading4 {
  font-size: 16px;
  line-height: 1.25;
  letter-spacing: 0.02em;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}
p {
  font-size: 16px;
}
@media (max-width: 500px) {
  p {
    font-size: 12px;
  }
}
.-mobile {
  display: none;
}
@media (max-width: 768px) {
  .-mobile {
    display: block;
  }
}
@media (max-width: 768px) {
  .-desktop {
    display: none;
  }
}
ul {
  list-style: none;
  padding: 0;
}
/*# sourceMappingURL=src/js/index.css.map */