@import "~vars"

.logo
	width 68px
	height 33px
	flexCenter()
	overflow-y hidden
	&.positano
		width 145px
	img
		object-fit cover