.intro {
  margin: 0 -15px;
  background-color: #ff8522;
  color: #fff;
  padding: 15px;
}
.intro.positano {
  background-color: #678ba5;
}
.intro .title {
  font-family: NovitoNova;
  font-size: 46px;
  line-height: 55px;
  margin: 0;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.intro .description {
  font-size: 14px;
  line-height: 16px;
}
.intro-positano-flag {
  height: 10px;
  margin: 0 -15px;
  background: -webkit-linear-gradient(left, #c5c1d1 16.7%, #9f1b20 16.7%, #9f1b20 33.3%, #37353d 33.3%, #37353d 50%, #c5c1d1 50%, #c5c1d1 66.7%, #9f1b20 66.7%, #9f1b20 83.3%, #37353d 83.3%);
  background: -moz-linear-gradient(left, #c5c1d1 16.7%, #9f1b20 16.7%, #9f1b20 33.3%, #37353d 33.3%, #37353d 50%, #c5c1d1 50%, #c5c1d1 66.7%, #9f1b20 66.7%, #9f1b20 83.3%, #37353d 83.3%);
  background: -o-linear-gradient(left, #c5c1d1 16.7%, #9f1b20 16.7%, #9f1b20 33.3%, #37353d 33.3%, #37353d 50%, #c5c1d1 50%, #c5c1d1 66.7%, #9f1b20 66.7%, #9f1b20 83.3%, #37353d 83.3%);
  background: -ms-linear-gradient(left, #c5c1d1 16.7%, #9f1b20 16.7%, #9f1b20 33.3%, #37353d 33.3%, #37353d 50%, #c5c1d1 50%, #c5c1d1 66.7%, #9f1b20 66.7%, #9f1b20 83.3%, #37353d 83.3%);
  background: linear-gradient(to right, #c5c1d1 16.7%, #9f1b20 16.7%, #9f1b20 33.3%, #37353d 33.3%, #37353d 50%, #c5c1d1 50%, #c5c1d1 66.7%, #9f1b20 66.7%, #9f1b20 83.3%, #37353d 83.3%);
}
/*# sourceMappingURL=src/js/components/Intro/Intro.css.map */