.input-counter {
  display: inline-block;
  width: 90px;
  border: 1px solid #a8a7a7;
  border-radius: 20px;
  height: 30px;
  line-height: 1;
  overflow: hidden;
}
.input-counter div {
  float: left;
  padding: 6px;
}
.input-counter div::selection {
  background: transparent;
}
.input-counter .input-counter__increment.increment.disabled {
  pointer-events: none;
  background-color: #d3d3d3;
  color: #a8a7a7;
}
.input-counter .input-counter__increment.increment {
  width: 23px;
}
.input-counter .input-counter__increment {
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  width: 20px;
  font-weight: bold;
  color: #a8a7a7;
  -webkit-tap-highlight-color: transparent;
}
.input-counter .decrement {
  line-height: 0.8;
}
.input-counter .input-counter__count-value {
  width: 45px;
  text-align: center;
  border-left: 1px solid #a8a7a7;
  border-right: 1px solid #a8a7a7;
}
.input-counter .input-counter__count-input {
  border: none;
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #333;
  outline: none;
  font-size: 14px;
  line-height: 16px;
}
/*# sourceMappingURL=src/js/components/Cart/CartItemCounter/CartItemCounter.css.map */