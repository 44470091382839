#header {
  width: 100%;
  min-width: 280px;
  height: auto;
  position: fixed;
  left: 0;
  top: 0;
  -webkit-box-shadow: 0 0 12px rgba(0,0,0,0.2);
  box-shadow: 0 0 12px rgba(0,0,0,0.2);
  pointer-events: none;
  z-index: 10;
}
#header .header-wrapper {
  border-bottom: 1px solid #e8e8e8;
  height: 60px;
  width: 100%;
  background: #fff;
  pointer-events: auto;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
#header .header-wrapper .header-left {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
#header .header-wrapper .header-left.cart-menu {
  width: 100%;
}
#header .header-wrapper .header-left.cart-menu .cart-menu-button {
  position: absolute;
  color: #999;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  -webkit-tap-highlight-color: transparent;
}
#header .header-wrapper .header-left.cart-menu .cart-menu-button .icon-left-arrow {
  width: 13px;
}
#header .header-wrapper .header-left.cart-menu .cart-menu-button span {
  margin: 2px 0 0 10px;
}
#header .header-wrapper .header-left.cart-menu .cart-menu-title {
  margin: auto;
  font-family: "PFDinTextCompPro", Helvetica, Arial, sans-serif;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
}
#header .header-wrapper .header-right {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
#header .header-wrapper .header-right .search-btn {
  width: 24px;
  height: 24px;
  margin-right: 16px;
  color: #a8a7a7;
  cursor: pointer;
}
#header .header-wrapper .header-right .search-btn.active {
  color: #ff8522;
}
#header .header-wrapper .header-right .search-btn svg {
  width: 24px;
  height: 24px;
}
#header .header-wrapper .header-right .tooltip {
  position: relative;
  margin-right: 16px;
  z-index: 20;
}
#header .header-wrapper .header-right .tooltip:hover:after {
  border: solid;
  border-color: #fff transparent;
  border-width: 0 12px 12px 12px;
  content: "";
  left: 50%;
  -webkit-transform: translate(-50%, 18px);
  -moz-transform: translate(-50%, 18px);
  -o-transform: translate(-50%, 18px);
  -ms-transform: translate(-50%, 18px);
  transform: translate(-50%, 18px);
  bottom: 0;
  position: absolute;
}
#header .header-wrapper .header-right .tooltip:hover .tooltip-data {
  display: block;
}
#header .header-wrapper .header-right .tooltip .tooltip-data {
  display: none;
  position: absolute;
  background: #fff;
  color: #333;
  margin: 0;
  font-size: 16px;
  padding: 15px;
  max-width: 200px;
  width: 70vw;
  height: auto;
  -webkit-box-shadow: 0 0 12px rgba(51,51,51,0.3);
  box-shadow: 0 0 12px rgba(51,51,51,0.3);
  left: 40px;
  bottom: -12px;
  -webkit-transform: translate(-100%, 100%);
  -moz-transform: translate(-100%, 100%);
  -o-transform: translate(-100%, 100%);
  -ms-transform: translate(-100%, 100%);
  transform: translate(-100%, 100%);
}
#header .header-wrapper .header-right .tooltip .tooltip-data>p {
  margin: 0;
}
#header .header-wrapper .header-right .tooltip .tooltip-data .store {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: -0.05em;
  margin-bottom: 18px;
}
#header .header-wrapper .header-right .tooltip .tooltip-data .table {
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
}
#header .header-wrapper .header-right .tooltip .icon-marker-outline {
  margin: 0 auto;
  color: #a8a7a7;
  width: 19px;
  height: 24px;
}
#header .header-wrapper .header-right .tooltip .icon-marker-outline:hover {
  color: #ff8522;
}
#header .header-wrapper .header-right a {
  padding-left: 0;
  padding-right: 16px;
}
#header .header-wrapper .header-right a .menu-header_button-number {
  letter-spacing: 0.02em;
  pointer-events: auto;
  cursor: pointer;
  line-height: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #f93;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 12px;
  text-align: center;
  position: absolute;
  border-radius: 10px;
  right: 8px;
  top: 2px;
}
#header .header-wrapper .header-right a .menu-header_button-number .menu-header_button-value {
  letter-spacing: 0.02em;
  pointer-events: auto;
  cursor: pointer;
  line-height: 1;
  color: #fff;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#header .header-wrapper .logo {
  margin: 10px 15px 5px;
}
#header .header-wrapper .logo.positano {
  margin-left: 3px;
}
#header .header-wrapper .mobile-menu-link {
  -webkit-tap-highlight-color: transparent;
}
/*# sourceMappingURL=src/js/components/Layout/Header/Header.css.map */