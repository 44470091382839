.modal.hide
	display none
.modal.show
	position: fixed;
	left: 0;
	top: 0;
	height: 100vh;
	background: rgba(0,0,0,0.65);
	width: 100%;
	pointer-events: auto;
	box-shadow: 1px 0 20px -10px #000;
	transition: left 0.35s;
	z-index 101
	overflow hidden
	display flex
	align-items center
	justify-content center
	.modal-main
		position absolute
		background: #FFFFFF;
		border-radius: 10px;
		min-width 250px
		padding 46px 14px 20px
		max-width 90%
		.close-button
			width 12px
			height 12px
			color #A8A7A7
			position absolute
			top 15px
			right 15px
		.title
			font-family: ProximaNova;
			font-size: 21px;
			line-height: 18px;
			text-align: center;
			color: #333333;
			margin 0 0 5px 0
		.description
			font-family: ProximaNova;
			font-size: 18px;
			line-height: 18px;
			color: #333333;
			margin 0