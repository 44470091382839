
@import "~vars"

.loader
	position relative
	//z-index 2

	&:before
		content ''
		position absolute
		left 50%
		top 50%
		right 100%
		height 4px
		margin -4px 0
		background $primary
		animation 2.5s LOADER infinite linear
		border-radius @height

.loader--violet:before
	background $violet

.loader--green:before
	background $green

.loader--cyan:before
	background $cyan

.loader--olive:before
	background $olive

.loader--dark:before
	background black

$wid = 20
@keyframes LOADER {
	0% {
		left 0
		right 100%
	}

	10% {
		left 0
		right (100 - $wid) %
	}

	40% {
		left (100 - $wid) %
		right 0
	}

	50% {
		left 100%
		right 0
	}

	60% {
		left (100 - $wid) %
		right 0
	}

	90% {
		left 0
		right (100 - $wid) %
	}

	100% {
		left 0
		right 100%
	}
}