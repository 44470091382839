.search-panel-wrap {
  height: auto;
  pointer-events: auto;
  position: relative;
}
.search-panel-wrap .search-field {
  height: 50px;
  padding: 10px 15px;
  background-color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.search-panel-wrap .search-input {
  width: 100%;
  border: none;
  background-color: transparent;
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  font-size: 19px;
  line-height: 1;
  color: #333;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.search-panel-wrap .clear-btn {
  display: none;
  margin: 0 5px 0 15px;
  cursor: pointer;
  color: #333;
}
.search-panel-wrap .clear-btn.visible {
  display: block;
}
.search-panel-wrap .clear-btn .icon-cross,
.search-panel-wrap .clear-btn svg {
  width: 10px;
  height: 10px;
}
.search-panel-wrap .backdrop {
  min-height: calc(100vh - 106px);
  background-color: rgba(0,0,0,0.65);
}
.search-panel-wrap .result-preview-wrap {
  width: 100%;
  max-height: 225px;
  position: absolute;
  top: 50px;
  overflow-y: auto;
}
.search-panel-wrap .result-preview-wrap .no-data {
  padding: 40px;
}
.search-panel-wrap .result-preview-wrap .no-data:before {
  content: none;
}
.search-panel-wrap .result-list {
  padding: 0;
  margin: 0;
}
.search-panel-wrap .result-list li {
  padding: 0;
  margin: 0;
}
.search-panel-wrap .result-li {
  height: 45px;
  padding: 5px 15px;
  background-color: #f2f2f2;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  font-size: 13px;
  line-height: 1.15;
}
.search-panel-wrap .result-li .image {
  width: 35px;
  height: 35px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  padding-right: 10px;
  text-align: center;
}
.search-panel-wrap .result-li .title {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-height: 28px;
  word-break: break-word;
  overflow: hidden;
  padding: 0 10px 0 0;
}
.search-panel-wrap .result-li .price {
  margin-left: auto;
}
/*# sourceMappingURL=src/js/components/SearchPanel/SearchPanel.css.map */