.loader {
  position: relative;
}
.loader:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  right: 100%;
  height: 4px;
  margin: -4px 0;
  background: #ff8522;
  -webkit-animation: 2.5s LOADER infinite linear;
  -moz-animation: 2.5s LOADER infinite linear;
  -o-animation: 2.5s LOADER infinite linear;
  -ms-animation: 2.5s LOADER infinite linear;
  animation: 2.5s LOADER infinite linear;
  border-radius: 4px;
}
.loader--violet:before {
  background: #573f7b;
}
.loader--green:before {
  background: #4bb7a1;
}
.loader--cyan:before {
  background: #218fa3;
}
.loader--olive:before {
  background: #b7be00;
}
.loader--dark:before {
  background: #000;
}
@-moz-keyframes LOADER {
  0% {
    left: 0;
    right: 100%;
  }
  10% {
    left: 0;
    right: 80%;
  }
  40% {
    left: 80%;
    right: 0;
  }
  50% {
    left: 100%;
    right: 0;
  }
  60% {
    left: 80%;
    right: 0;
  }
  90% {
    left: 0;
    right: 80%;
  }
  100% {
    left: 0;
    right: 100%;
  }
}
@-webkit-keyframes LOADER {
  0% {
    left: 0;
    right: 100%;
  }
  10% {
    left: 0;
    right: 80%;
  }
  40% {
    left: 80%;
    right: 0;
  }
  50% {
    left: 100%;
    right: 0;
  }
  60% {
    left: 80%;
    right: 0;
  }
  90% {
    left: 0;
    right: 80%;
  }
  100% {
    left: 0;
    right: 100%;
  }
}
@-o-keyframes LOADER {
  0% {
    left: 0;
    right: 100%;
  }
  10% {
    left: 0;
    right: 80%;
  }
  40% {
    left: 80%;
    right: 0;
  }
  50% {
    left: 100%;
    right: 0;
  }
  60% {
    left: 80%;
    right: 0;
  }
  90% {
    left: 0;
    right: 80%;
  }
  100% {
    left: 0;
    right: 100%;
  }
}
@keyframes LOADER {
  0% {
    left: 0;
    right: 100%;
  }
  10% {
    left: 0;
    right: 80%;
  }
  40% {
    left: 80%;
    right: 0;
  }
  50% {
    left: 100%;
    right: 0;
  }
  60% {
    left: 80%;
    right: 0;
  }
  90% {
    left: 0;
    right: 80%;
  }
  100% {
    left: 0;
    right: 100%;
  }
}
/*# sourceMappingURL=src/js/components/UI/Loader/Loader.css.map */