.menu-catalog {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  padding-bottom: 60px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -o-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.menu-catalog.open {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -o-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.menu-catalog .catalog {
  width: 100%;
  min-width: 280px;
  max-width: 500px;
  max-height: 100%;
  padding: 20px 50px;
  margin: 0 auto;
  overflow: auto;
}
.menu-catalog .sub-catalog {
  display: none;
  padding-left: 15px;
}
.menu-catalog .category-item,
.menu-catalog .sub-category-item {
  height: auto;
  min-height: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.menu-catalog .category-item {
  font-size: 16px;
}
.menu-catalog .category-item.active {
  color: #ff8522;
}
.menu-catalog .category-item.active .sub-catalog {
  display: block;
}
.menu-catalog .category-item.active .category-item-title .icon-chevron-down {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
.menu-catalog .sub-category-item {
  font-size: 14px;
  color: #000;
}
.menu-catalog .sub-category-item.active {
  color: #ff8522;
}
.menu-catalog .category-item-title {
  height: 30px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.menu-catalog .category-item-title .icon-chevron-down {
  margin-left: 15px;
}
.menu-catalog .category-item-title .icon-chevron-down svg {
  width: 11px;
  height: 11px;
}
/*# sourceMappingURL=src/js/components/Layout/MenuCatalog/MenuCatalog.css.map */