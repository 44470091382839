.phone_input_holder {
  padding: 0 15px 15px;
  width: 100%;
  min-height: 40px;
}
.phone_input_holder input {
  width: 100%;
  outline: none;
  border: 0;
  font-size: 14px;
  line-height: 12px;
  font-family: $proxima;
  padding-bottom: 5px;
  border-bottom: 1px solid #999;
}
.phone_input_holder input:focus {
  border-bottom: 2px solid #573f7b;
}
.phone_input_holder input::placeholder {
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.02em;
}
/*# sourceMappingURL=src/js/components/PhoneInput/PhoneInput.css.map */