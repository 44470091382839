.logo {
  width: 68px;
  height: 33px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow-y: hidden;
}
.logo.positano {
  width: 145px;
}
.logo img {
  object-fit: cover;
}
/*# sourceMappingURL=src/js/components/UI/Logo/Logo.css.map */