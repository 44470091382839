@import "~vars"


.search-panel-wrap
	height auto
	pointer-events auto
	position relative

	.search-field
		height 50px
		padding 10px 15px
		background-color white
		display flex
		align-items center

	.search-input
		width 100%
		border none
		background-color transparent
		font-family $font
		font-size 19px
		line-height 1
		color $color
		-webkit-box-shadow none
		-moz-box-shadow none
		box-shadow none
		outline none

	.clear-btn
		display none
		margin 0 5px 0 15px
		cursor pointer
		color $color
		&.visible
			display block
		.icon-cross
		svg
			size(10px)

	.backdrop
		min-height calc(100vh - 106px)
		background-color rgba(0 0 0 0.65)

	.result-preview-wrap
		width 100%
		max-height 225px
		position absolute
		top 50px
		overflow-y auto
		.no-data
			padding 40px
			&:before
				content none

	.result-list
		padding 0
		margin 0
		li
			padding 0
			margin 0

	.result-li
		height 45px
		padding 5px $gutter
		background-color $disabledBg
		display flex
		align-items center
		font-size 13px
		line-height 1.15
		.image
			size(35px)
			box-sizing content-box
			padding-right 10px
			text-align center
		.title
			flex 1
			max-height 28px
			word-break break-word
			overflow hidden
			padding 0 10px 0 0
		.price
			margin-left auto