.icon {
  display: inline-block;
  vertical-align: middle;
}
.icon img,
.icon svg {
  display: block;
}
.icon:not(.raw) svg path,
.icon:not(.raw) svg polygon,
.icon:not(.raw) svg rect,
.icon:not(.raw) svg circle {
  fill: currentColor;
}
.icon-shape--circle {
  border-radius: 100%;
  width: 28px;
  height: 28px;
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  background: #ff8522;
  color: #fff;
  margin: -2px 15px 0 10px;
}
.icon-shape--circle svg {
  margin: auto;
}
.icon-theme--orange,
.icon-theme--violet,
.icon-theme--cyan,
.icon-theme--green,
.icon-theme--olive {
  color: #fff;
  background-color: #333;
}
.icon-theme--orange {
  background-color: #faa30c;
}
.icon-theme--violet {
  background-color: #573f7b;
}
.icon-theme--cyan {
  background-color: #218fa3;
}
.icon-theme--green {
  background-color: #4bb7a1;
}
.icon-theme--ltgreen {
  background-color: #97be00;
}
.icon-theme--olive {
  background-color: #b7be00;
}
.icon-theme--brown {
  background-color: $brown;
}
.icon-phone {
  width: 16px;
  height: 25px;
  color: #ff8522;
}
.icon-user-account svg,
.icon-cross svg,
.icon-cross-thin svg,
.icon-clock svg,
.icon-credit-card svg,
.icon-arrow-right svg,
.icon-price-cut svg,
.icon-search svg,
.icon-right-arrow svg,
.icon-left-arrow svg,
.icon-circle-check svg,
.icon-mail svg,
.icon-print svg,
.icon-invoice svg,
.icon-note svg,
.icon-faq svg,
.icon-cart-block-basket svg,
.icon-cart-block-order svg {
  width: 100%;
  height: 100%;
}
.icon-user-account {
  width: 22px;
  height: 25px;
}
.icon-small-calendar {
  width: 22px;
  height: 22px;
}
.icon-calendar {
  width: 22px;
  height: 22px;
}
.icon-marker-outline {
  width: 22px;
  height: 22px;
}
.icon-cross {
  width: 18px;
  height: 18px;
}
.icon-cross-thin {
  width: 44px;
  height: 44px;
}
.icon-clock {
  width: 30px;
  height: 30px;
}
.icon-credit-card {
  width: 22px;
  height: 22px;
}
.icon-arrow-right {
  width: 13px;
  height: 12px;
}
.icon-price-cut {
  width: 46px;
  height: 13px;
}
.icon-right-arrow,
.back-link__icon {
  width: 18px;
  height: 16px;
}
.icon-search {
  width: 20px;
  height: 20px;
}
.icon-circle-check {
  width: 15px;
  height: 15px;
}
.icon-print {
  width: 30px;
  height: 30px;
}
.icon-invoice {
  width: 28px;
  height: 36px;
}
.icon-exit {
  width: 25px;
  height: 25px;
}
.icon-mail {
  width: 30px;
  height: 26px;
}
.icon-clock-thin {
  width: 22px;
  height: 22px;
}
.icon-clock-thin svg {
  width: 22px;
  height: 22px;
}
.icon-delete {
  width: 20px;
  height: 20px;
}
.icon-proposal-map {
  width: 24px;
  height: 24px;
}
.icon-basket {
  width: 30px;
  height: 30px;
  color: #a8a7a7;
}
.icon-timer-clock {
  width: 24px;
  height: 24px;
  color: #fff;
}
.icon-money-coins-blue,
.icon-phone-blue {
  width: 30px;
  height: 30px;
}
.icon-youtube-logo {
  width: 92px;
  height: 92px;
}
/*# sourceMappingURL=src/js/components/UI/Icon/Icon.base.css.map */