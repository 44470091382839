.cart-item {
  border-bottom: 1px solid rgba(153,153,153,0.278);
}
.cart-item .cart-item-wrapper {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding-top: 15px;
}
.cart-item .cart-item-wrapper .cart-item-image {
  max-width: 40%;
  padding: 0 10px 0 15px;
}
.cart-item .cart-item-wrapper .cart-item-details {
  width: 60%;
}
.cart-item .cart-item-wrapper .cart-item-details .title-with-icon,
.cart-item .cart-item-wrapper .cart-item-details .price-with-icon,
.cart-item .cart-item-wrapper .cart-item-details .counter-with-total {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  padding-right: 10px;
}
.cart-item .cart-item-wrapper .cart-item-details .title-with-icon .icon {
  color: #999;
}
.cart-item .cart-item-wrapper .cart-item-details .price-with-icon {
  margin-top: 20px;
}
.cart-item .cart-item-wrapper .cart-item-details .price-with-icon .icon-comments {
  color: #999;
}
.cart-item .cart-item-wrapper .cart-item-details .price-with-icon .icon-comments.active {
  color: #ff8522;
}
.cart-item .cart-item-wrapper .cart-item-details .cart-item-portion-title {
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: #333;
}
.cart-item .cart-item-wrapper .cart-item-details .icon-trash-bucket svg {
  width: 15px;
  height: 18px;
}
.cart-item .cart-item-wrapper .cart-item-details .cart-item-price {
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  color: #333;
  font-size: 13px;
  line-height: 16px;
}
.cart-item .cart-item-wrapper .cart-item-details .icon-comments svg {
  width: 20px;
  height: 20px;
}
.cart-item .counter-with-total {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 15px;
}
.cart-item .counter-with-total .cart-item-total-price {
  font-family: "ProximaNova", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
/*# sourceMappingURL=src/js/components/Cart/CartItem/CartItem.css.map */