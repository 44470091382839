@import "~vars"

.input-counter
	display inline-block
	width 90px
	border 1px solid $gray
	border-radius 20px
	height 30px
	line-height 1
	overflow hidden

	div
		float left
		padding 6px

		&::selection {
			background: transparent;
		}

	.input-counter__increment.increment.disabled
		pointer-events none
		background-color #d3d3d3
		color $gray

	.input-counter__increment.increment
		width 23px

	.input-counter__increment
		cursor pointer
		text-align center
		font-size 20px
		width 20px
		font-weight bold
		color $gray
		-webkit-tap-highlight-color transparent

	.decrement
		line-height 0.8


	.input-counter__count-value
		width 45px
		text-align center
		border-left 1px solid  $gray
		border-right 1px solid $gray

	.input-counter__count-input
		border none
		padding 0
		width 100%
		height 100%
		text-align center
		color #333
		outline none
		font-size 14px
		line-height 16px